<template>
  <b-card class="email-content-card">
    <div style="display: flex; justify-content: center; ">
      <b-row align-v="center" align-h="center">
        <b-col lg="5" sm="12" class="mb-3" align="center">
          <img src="../../assets/images/lock-screen.png" alt=""/>
        </b-col>
        <b-col lg="7" sm="12">
          <b-card class="inner-card">
            <label class="text-white" style="font-size: 20px">
              {{ $t('account.verifyEmail') }}
            </label>
            <h5 class="text-white mb-5">
              {{ $t('account.verifyIdentity') }}
            </h5>
            <div class="email-address mb-3">
              {{ email }}
            </div>
            <div style="color: #fff;margin-bottom: 10px;">
              {{ $t('account.notRecieve') }}
              <span
                  v-if="countdown === 0 && countdown_error === 0"
                  @click="resendEmail"
                  style="
                    color: #7367f0;
                    cursor: pointer;
                    text-decoration: underline;
                  "
              >
                  {{ $t('account.emailAgian') }}
                </span>
              <span v-else style="color: #7367f0;">Resend email in {{countdown_error !== 0 ? countdown_error : countdown}} sec.</span>
            </div>
            <div v-if="isResend" style="color: red; margin-bottom: 5px;">
              Email has been send.
            </div>
            <div align="center" class="mt-5">
              <b-button
                  class="mt-4"
                  pill
                  variant="gradient"
                  @click="() => { $router.push('Login') }">
                {{ $t('account.toLogin') }}
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import profileApi from "@/repository/profileApi";
import alert from "@/common/alert";

export default {
  name: 'verifyEmail',
  data() {
    return {
      email: '',
      isResend: false,
      forgotSuccess: false,
      countdown: 0,
      countdown_error: 0,
      timer: 0
    }
  },
  created() {
    this.email = localStorage.getItem('signup_email')
    if (!this.email) {
      this.$router.push('Login').catch(() => {
      })
    }
  },
  beforeDestroy() {
    localStorage.removeItem('signup_email')
  },
  methods: {
    resendEmail() {
      const data = {
        email: this.email,
        verify_url: window.location.origin + '/Confirm-Email'
      }
      profileApi.resendVerify(data).then((response) => {
        const rawData = response?.data
        let rate = Number(response.headers['ratelimit-reset'])
        if (rawData?.codeSYS === '001') {
          let timer = rate;
          this.forgotSuccess = true
          let interval = setInterval(() => {
            if (timer === 0) {
              clearInterval(interval)
              this.countdown = 0
            } else {
              this.countdown = timer--
            }
          }, 1000)
        } else {
          alert.resendFailed(this, rawData?.message)
        }
      }).catch((err) => {
        if (err.response.status === 429) {
          let rate = Number(err.response.headers['ratelimit-reset'])
          let timer = rate;
          let interval = setInterval(() => {
            if (timer === 0) {
              clearInterval(interval)
              this.countdown_error = 0
            } else {
              this.countdown_error = timer--
            }
          }, 1000)
        } else {
          alert.resendFailed(this, err.response.data.message)
        }
      })
    },
  },
}
</script>

<style scoped>
.email-content-card {
  display: block;
  max-width: 1300px;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner-card {
  height: 100%;
  width: 400px;
  background-color: rgb(175, 175, 175);
}

.email-address {
  background-color: #fff;
  height: 40px;
  border-radius: 5px;
  padding: 10px;
  color: #003adc;
  margin-bottom: 10px;
}
</style>
